@use 'vars' as *;

@mixin form_all {
    @include form_group();
    @include form_label();
    @include form_addon();
    @include form_control();
    @include form_help();
}

@mixin form_group {
    .form-group {
        display: flex;
        flex-direction: column;
    }
}

@mixin form_label {
    label.control-label {
        font-size: $size-small;
        font-weight: 600;
        color: $black;
        text-align: left;
        /*margin-bottom: 8px;*/

        //override metronic components-rounded.css
        &[class*="col-"].required {
            padding-left: 15px;
        }
    }
}

@mixin form_addon {
    .form-addon--radius-left {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    .form-addon--radius-right {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .input-group-addon {
        &:first-child {
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
        }
        &:last-child {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
        }
    }
}

@mixin form_control {
    .form-control {
        color: $black;
        border-radius: 25px;
        box-shadow: 0px 3px 6px rgba(0, 134, 162, 0.15) !important;

        &.form-item--no-radius-left {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &.form-item--no-radius-right {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

@mixin form_help {
    .form-help {
        color: $white;
    }
}
