@use '../../base/scss/form' as ec-form;
@use '../../base/scss/vars' as *;
@use "include-media" as im;

.layout-anon main .login {
    .login__form {
        &__remember-forget {
            &__remember, &__forget a {
                color: $white;
            }

            &__remember {
                &.mt-checkbox>span:after {
                    border-color: $white;
                }
            }

            &__forget {
                @include im.media(">=medium") {
                    & {
                        text-align: right;
                    }
                }
            }
        }
    }
}
