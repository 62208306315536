@use '../../base/scss/form' as ec-form;
@use "include-media" as im;

.layout-anon main .request_reset_pwd,
.layout-anon main .resetting_pwd
{
    &__form {
        .form-group {
            flex-direction: column;
            align-items: start;
        }

        &__submit {
            justify-content: left;
            margin-left: 0;

            button {
                margin-left: 0;
            }
        }
    }
}
