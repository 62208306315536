@use '../../base/scss/form' as ec-form;
@use '../../base/scss/vars' as *;
@use "include-media" as im;

.layout-anon main {
    .anon-container > div,
    .anon-container > p
    {
        width: 600px;
        color: $white;

        @include im.media("<medium") {
            & {
                width: 100%;
            }
        }
    }

    .anon-container > p {
        display: block;
    }

    .anon__form {
        .form-title {
            font-size: $size-title;
            margin-bottom: 32px;
            color: $white;
        }

        @include ec-form.form_all();

        .form-group {
            flex-direction: row;
            align-items: center;

            @include im.media("<medium") {
                & {
                    flex-direction: column;
                    align-items: normal;
                }
            }
        }

        label.control-label {
            display: inline-block;
            font-size: $size-default;
            font-weight: initial;
            margin-right: 24px;
            color: $white;
        }

        .form-control {
            display: inline-block;
            width: 385px;
            height: 50px;
            padding: 15px 34px;
            font-weight: 600;
            box-shadow: 0px 3px 6px rgba(72, 71, 54, 0.16) !important;

            @include im.media("<medium") {
                & {
                    width: 75%;
                }
            }

            @include im.media("<small") {
                & {
                    width: 100%;
                }
            }
        }

        &__error {
            color: $white;
            font-weight: 600;
        }

        &__submit {
            display: flex;
            flex-direction: row;
            justify-content: center;

            @include im.media(">=small", "<medium") {
                & {
                    justify-content: left;
                    margin-left: 20%;
                }
            }

            button {
                margin: 32px;

                /*background-color: transparent;
                border: 2px solid $white;
                color: $white;

                &::after {
                    filter: brightness(0) invert(1);
                }*/
            }
        }
    }
}