.layout-anon {
    main {
        height: 100%;
        width: 100%;

        background: url('/theme/earlycareer/images/login-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;

        >div.container {
            height: 100%;
        }

        div.anon-container {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}