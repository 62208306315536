.layout-anon main .anon-container > div,
.layout-anon main .anon-container > p {
  width: 600px;
  color: #FFFFFF;
}
@media (max-width: 991px) {
  .layout-anon main .anon-container > div,
  .layout-anon main .anon-container > p {
    width: 100%;
  }
}
.layout-anon main .anon-container > p {
  display: block;
}
.layout-anon main .anon__form .form-title {
  font-size: 32px;
  margin-bottom: 32px;
  color: #FFFFFF;
}
.layout-anon main .anon__form .form-group {
  display: flex;
  flex-direction: column;
}
.layout-anon main .anon__form label.control-label {
  font-size: 14px;
  font-weight: 600;
  color: #2b2b28;
  text-align: left;
  /*margin-bottom: 8px;*/
}
.layout-anon main .anon__form label.control-label[class*=col-].required {
  padding-left: 15px;
}
.layout-anon main .anon__form .form-addon--radius-left {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.layout-anon main .anon__form .form-addon--radius-right {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.layout-anon main .anon__form .input-group-addon:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.layout-anon main .anon__form .input-group-addon:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.layout-anon main .anon__form .form-control {
  color: #2b2b28;
  border-radius: 25px;
  box-shadow: 0px 3px 6px rgba(0, 134, 162, 0.15) !important;
}
.layout-anon main .anon__form .form-control.form-item--no-radius-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.layout-anon main .anon__form .form-control.form-item--no-radius-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.layout-anon main .anon__form .form-help {
  color: #FFFFFF;
}
.layout-anon main .anon__form .form-group {
  flex-direction: row;
  align-items: center;
}
@media (max-width: 991px) {
  .layout-anon main .anon__form .form-group {
    flex-direction: column;
    align-items: normal;
  }
}
.layout-anon main .anon__form label.control-label {
  display: inline-block;
  font-size: 16px;
  font-weight: initial;
  margin-right: 24px;
  color: #FFFFFF;
}
.layout-anon main .anon__form .form-control {
  display: inline-block;
  width: 385px;
  height: 50px;
  padding: 15px 34px;
  font-weight: 600;
  box-shadow: 0px 3px 6px rgba(72, 71, 54, 0.16) !important;
}
@media (max-width: 991px) {
  .layout-anon main .anon__form .form-control {
    width: 75%;
  }
}
@media (max-width: 766px) {
  .layout-anon main .anon__form .form-control {
    width: 100%;
  }
}
.layout-anon main .anon__form__error {
  color: #FFFFFF;
  font-weight: 600;
}
.layout-anon main .anon__form__submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (min-width: 767px) and (max-width: 991px) {
  .layout-anon main .anon__form__submit {
    justify-content: left;
    margin-left: 20%;
  }
}
.layout-anon main .anon__form__submit button {
  margin: 32px;
  /*background-color: transparent;
  border: 2px solid $white;
  color: $white;

  &::after {
      filter: brightness(0) invert(1);
  }*/
}

.layout-anon main {
  height: 100%;
  width: 100%;
  background: url("/theme/earlycareer/images/login-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.layout-anon main > div.container {
  height: 100%;
}
.layout-anon main div.anon-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.layout-anon main .login .login__form__remember-forget__remember, .layout-anon main .login .login__form__remember-forget__forget a {
  color: #FFFFFF;
}
.layout-anon main .login .login__form__remember-forget__remember.mt-checkbox > span:after {
  border-color: #FFFFFF;
}
@media (min-width: 992px) {
  .layout-anon main .login .login__form__remember-forget__forget {
    text-align: right;
  }
}

.layout-anon main .request_reset_pwd__form .form-group,
.layout-anon main .resetting_pwd__form .form-group {
  flex-direction: column;
  align-items: start;
}
.layout-anon main .request_reset_pwd__form__submit,
.layout-anon main .resetting_pwd__form__submit {
  justify-content: left;
  margin-left: 0;
}
.layout-anon main .request_reset_pwd__form__submit button,
.layout-anon main .resetting_pwd__form__submit button {
  margin-left: 0;
}