$black: #2b2b28;
$white: #FFFFFF;
$beige: #F2F2EE;

$grey: #807e74;
$middle-grey: #9F9E93;
$light-grey: #f2f2e6;

$purple: #7040ab;
$light-purple: #b6a3f9;

$red: #FC4564;
$light-red: #FBE1E3;

//To check
//$orange: orange;
//$light-orange: #fcf8e3;

$green: #8FCF2F;
$light-green: #dff0d8;

$blue: #00b1d7;
$dark-blue: #0086a2;
$light-blue: #C5EEF6;
$blue-active: #00B1D7;
$blue-nav: #1EC9EE;

$line : #D2D2C5;

$table-row-odd-bg: #FAFAF1; //250, 250, 241
$table-row-even-bg: #FFFFFF; //255, 255, 255
$table-row-odd-selected-bg: #EDEEE8; //237, 238, 232 (250-12, 250-11, 250-9)
$table-row-even-selected-bg: #F3F4F6; //243, 244, 246 (-12, -11, -9)
$table-border: #707070;

$size-default: 16px;
$size-title: 32px;
$size-small: 14px;
$size-xsmall: 12px;
$size-xxsmall: 8px;
$size-icon-close: 36px;
$size-icon-file: 20px;
$size-alert-icon: 22px;

$fileColorPerStatus: (
    'valid': $green,
    'refuse': $red,
    'missing': $grey,
    'deposit': $dark-blue
);
